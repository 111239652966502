<template>
  <picture
    ref="$el"
    :class="orientation"
    :style="style"
  >
    <img
      :alt="alt"
      :class="{ contains, loaded }"
      :fetchpriority="fetchPriority"
      :height="naturalHeight ? naturalHeight : computedHeight"
      :loading="loading"
      :src="src"
      :width="naturalWidth ? naturalWidth : computedWidth"
    >
  </picture>
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'image:loaded',
])

// Props
const props = defineProps({
  alt: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  contains: {
    default: false,
    required: false,
    type: Boolean,
  },
  extraParams: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  expectedSize: {
    required: true,
    type: [Number, Object],
  },
  height: {
    required: true,
    type: Number,
  },
  lazyload: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  orientation: {
    default() {
      return 'landscape'
    },
    required: false,
    type: String,
    validator(value) {
      return ['landscape', 'portrait'].indexOf(value) !== -1
    },
  },
  padWidth: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  preload: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  quality: {
    default() {
      return [90, 30, 15]
    },
    required: false,
    type: Array,
  },
  source: {
    required: true,
    type: String,
  },
  useNaturalHeight: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  useNaturalWidth: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  width: {
    required: true,
    type: Number,
  },
})

// Refs
const $el = ref(null)
const loaded = ref(false)
const naturalHeight = ref(null)
const naturalWidth = ref(null)
const observer = ref(null)
const src = ref('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')

// Computed Properties
const aspectRatio = computed(() => {
  return props.width / props.height
})

const computedHeight = computed(() => {
  switch (true) {
    case props.orientation === 'landscape' && aspectRatio.value > 1:
      return Math.round(props.expectedSize / aspectRatio.value)
    case props.orientation === 'portrait' && aspectRatio.value < 1:
      return Math.round(props.expectedSize / aspectRatio.value)
    default:
      return props.expectedSize
  }
})

const computedWidth = computed(() => {
  switch (true) {
    case props.orientation === 'landscape' && aspectRatio.value < 1:
      return Math.round(props.expectedSize * aspectRatio.value)
    case props.orientation === 'portrait' && aspectRatio.value > 1:
      return Math.round(props.expectedSize * aspectRatio.value)
    default:
      return props.expectedSize
  }
})

const fetchPriority = computed(() => {
  return props.lazyload ? 'low' : 'high'
})

const loading = computed(() => {
  return props.lazyload ? 'lazy' : 'eager'
})

const mimeType = computed(() => {
  return props.source?.split('.')?.pop().toLowerCase()
})

const params = computed(() => {
  const localParams = {}
  switch (true) {
    case props.orientation === 'landscape' && aspectRatio.value > 1:
      Object.assign(localParams, {
        h: 'auto',
        w: props.expectedSize,
      })
      break
    case props.orientation === 'landscape' && aspectRatio.value < 1:
      Object.assign(localParams, {
        h: props.expectedSize,
        w: 'auto',
      })
      break
    case props.orientation === 'portrait' && aspectRatio.value > 1:
      Object.assign(localParams, {
        h: props.expectedSize,
        w: 'auto',
      })
      break
    case props.orientation === 'portrait' && aspectRatio.value < 1:
      Object.assign(localParams, {
        h: 'auto',
        w: props.expectedSize,
      })
      break
    default:
      Object.assign(localParams, {
        h: props.expectedSize,
        w: props.expectedSize,
      })
  }
  return {
    ...localParams,
    ...props.extraParams,
  }
})

const sourceWithParams = computed(() => {
  const localParams = new URLSearchParams(params.value).toString()
  return `${props.source}?${localParams}&fm=${mimeType.value}&auto=format`
})

const sourcesSet = computed(() => {
  return `${sourceWithParams.value}&q=${props.quality[0]}&dpr=1 1x, ${sourceWithParams.value}&q=${props.quality[1]}&dpr=2 2x, ${sourceWithParams.value}&q=${props.quality[2]}&dpr=3 3x`
})

const style = computed(() => {
  const widthMargin = props.padWidth ? 1 : 0
  return {
    'aspect-ratio': `${(naturalWidth.value || computedWidth.value) + widthMargin} / ${
      naturalHeight.value || computedHeight.value
    }`,
    'min-height': `${naturalHeight.value || computedHeight.value}px`,
    'min-width': `${(naturalWidth.value || computedWidth.value) + widthMargin}px`,
    'height': `${naturalHeight.value || computedHeight.value}px`,
    'width': `${(naturalWidth.value || computedWidth.value) + widthMargin}px`,
  }
})

// Watchers
watch(() => loaded.value, (value) => {
  if (value) {
    emit('image:loaded')
  }
})

// Methods
function loadImage() {
  const image = $el.value.firstChild
  image.addEventListener('load', (event) => {
    if (props.useNaturalHeight) {
      naturalHeight.value = event.target.naturalHeight
    }

    if (props.useNaturalWidth) {
      naturalWidth.value = event.target.naturalWidth
    }

    loaded.value = true
  })

  if (mimeType.value === 'gif' || mimeType.value === 'svg') {
    image.src = props.source
  }
  else {
    image.srcset = sourcesSet.value
    image.src = sourceWithParams.value
  }
}

function observe() {
  observer.value = new IntersectionObserver(onObserve)
  observer.value.observe($el.value)
}

function onObserve([entry]) {
  if (entry.isIntersecting) {
    loadImage()
    unobserve()
  }
}

function unobserve() {
  observer.value?.disconnect()
  observer.value = null
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  loaded.value = false
  if (observer.value) {
    unobserve()
  }
})

onMounted(() => {
  if (props.lazyload) {
    observe()
  }
  else {
    loadImage()
  }
})

onUpdated(() => {
  if (!loaded.value) {
    if (props.lazyload) {
      observe()
    }
    else {
      loadImage()
    }
  }
})

// SEO
const link = computed(() => {
  return [
    props.preload
      ? {
          rel: 'preload',
          href: sourceWithParams.value,
          as: 'image',
          imagesrcset: sourcesSet.value,
        }
      : {},
  ]
})

useHead({
  link: link.value,
})
</script>

<style lang="postcss" scoped>
picture {
  @apply relative flex rounded-4xl overflow-hidden transition;

  img {
    @apply absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity duration-500 ease-out;

    &.contains {
      @apply object-contain !important;
    }

    &.loaded {
      @apply opacity-100;
    }
  }
}
</style>
