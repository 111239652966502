import { default as _91slug_9375STYcaflOMeta } from "/app/src/pages/[slug].vue?macro=true";
import { default as aboutv3Wuob7z2aMeta } from "/app/src/pages/about.vue?macro=true";
import { default as _91slug_93LA24mpzP4MMeta } from "/app/src/pages/agency-partners/[slug].vue?macro=true";
import { default as indexWmVTUjEEvlMeta } from "/app/src/pages/agency-partners/index.vue?macro=true";
import { default as _91slug_93VDxieYqxciMeta } from "/app/src/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93c9FzndEQN1Meta } from "/app/src/pages/blog/author/[slug].vue?macro=true";
import { default as _91id_93RzgEV1uC4aMeta } from "/app/src/pages/blog/author/page/[id].vue?macro=true";
import { default as _91slug_93U6TU4gewFHMeta } from "/app/src/pages/blog/category/[slug].vue?macro=true";
import { default as _91id_93xmV1jEDW26Meta } from "/app/src/pages/blog/category/page/[id].vue?macro=true";
import { default as indexUkEdP5hCQZMeta } from "/app/src/pages/blog/index.vue?macro=true";
import { default as _91id_931N4gy2EyUgMeta } from "/app/src/pages/blog/page/[id].vue?macro=true";
import { default as searchgCucCyoHBjMeta } from "/app/src/pages/blog/search.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as customer_45servicet20ZrocbOuMeta } from "/app/src/pages/customer-service.vue?macro=true";
import { default as _91slug_93ayOQmhjm9JMeta } from "/app/src/pages/customers/[slug].vue?macro=true";
import { default as _91id_931kqe4pCZxrMeta } from "/app/src/pages/customers/filter/[id].vue?macro=true";
import { default as index98zUuSNpEQMeta } from "/app/src/pages/customers/index.vue?macro=true";
import { default as _91id_93szjmHBnyEpMeta } from "/app/src/pages/customers/pages/[id].vue?macro=true";
import { default as enterpriseiryIcjYTovMeta } from "/app/src/pages/enterprise.vue?macro=true";
import { default as featuresjNY3o2IWTUMeta } from "/app/src/pages/features.vue?macro=true";
import { default as _91slug_933eivOnKgCNMeta } from "/app/src/pages/file/[slug].vue?macro=true";
import { default as _91slug_93HDAJZ3Zj6TMeta } from "/app/src/pages/ice-breaker-games/[slug].vue?macro=true";
import { default as indexSZNRv0LzkpMeta } from "/app/src/pages/ice-breaker-games/index.vue?macro=true";
import { default as _91id_93Ax9EWQpc7uMeta } from "/app/src/pages/ice-breaker-games/page/[id].vue?macro=true";
import { default as generatorGpxx0FXR50Meta } from "/app/src/pages/ice-breaker-questions/generator.vue?macro=true";
import { default as index89FfTu7xidMeta } from "/app/src/pages/ice-breaker-questions/index.vue?macro=true";
import { default as indexRccyW05sCWMeta } from "/app/src/pages/ice-breaker/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91tag_93kgO3ruNvcHMeta } from "/app/src/pages/industries/[slug]/[tag].vue?macro=true";
import { default as indexkw4tVOaQ5DMeta } from "/app/src/pages/industries/[slug]/index.vue?macro=true";
import { default as index1vsYImkN24Meta } from "/app/src/pages/industries/index.vue?macro=true";
import { default as _91slug_93qEj37DRaCCMeta } from "/app/src/pages/integrations/[slug].vue?macro=true";
import { default as indexa6kqw29fbJMeta } from "/app/src/pages/integrations/index.vue?macro=true";
import { default as legal_45centerYxOISCeytfMeta } from "/app/src/pages/legal-center.vue?macro=true";
import { default as onboardinglQRWnZpm6fMeta } from "/app/src/pages/onboarding.vue?macro=true";
import { default as online_45meeting_45softwareXjIw4KHqVIMeta } from "/app/src/pages/online-meeting-software.vue?macro=true";
import { default as _91slug_93MbhnreXBaTMeta } from "/app/src/pages/partners/[slug].vue?macro=true";
import { default as indexwRzRVWjFWlMeta } from "/app/src/pages/partners/index.vue?macro=true";
import { default as powered_45by_45livestormncoAycMHmqMeta } from "/app/src/pages/powered-by-livestorm.vue?macro=true";
import { default as pricing_45plansm8lEnXwwYfMeta } from "/app/src/pages/pricing-plans.vue?macro=true";
import { default as pricingUb7f7hCgwvMeta } from "/app/src/pages/pricing.vue?macro=true";
import { default as _91slug_934UBvbrZ8UqMeta } from "/app/src/pages/resources/[type]/[slug].vue?macro=true";
import { default as indexiTqH2duCGAMeta } from "/app/src/pages/resources/[type]/index.vue?macro=true";
import { default as _91slug_93aOzpkYR3OtMeta } from "/app/src/pages/resources/guides/[slug].vue?macro=true";
import { default as index6HJHYQwJzeMeta } from "/app/src/pages/resources/index.vue?macro=true";
import { default as _91id_93tpV2iXlWGmMeta } from "/app/src/pages/resources/page/[id].vue?macro=true";
import { default as _91slug_93OtBcB3l8bAMeta } from "/app/src/pages/resources/quiz/[slug].vue?macro=true";
import { default as restreamingjUQDN0z1IZMeta } from "/app/src/pages/restreaming.vue?macro=true";
import { default as social_45linksXLBXe77FYwMeta } from "/app/src/pages/social-links.vue?macro=true";
import { default as _91slug_93qtiZ0s673jMeta } from "/app/src/pages/teams/[slug].vue?macro=true";
import { default as indexTzaOJAwYaDMeta } from "/app/src/pages/teams/index.vue?macro=true";
import { default as _91slug_937nqoKmY8OIMeta } from "/app/src/pages/try/[slug].vue?macro=true";
import { default as _91slug_9316VKC81habMeta } from "/app/src/pages/use-cases/[slug].vue?macro=true";
import { default as indexPZ6H1ChzmkMeta } from "/app/src/pages/use-cases/index.vue?macro=true";
import { default as _91slug_93XnIc7UkZ5GMeta } from "/app/src/pages/video-glossary/[slug].vue?macro=true";
import { default as indexMQu7d3rbdrMeta } from "/app/src/pages/video-glossary/index.vue?macro=true";
import { default as _91slug_93IFhx9CVczbMeta } from "/app/src/pages/virtual-background-library/[slug].vue?macro=true";
import { default as _91slug_93ue005eES5pMeta } from "/app/src/pages/virtual-background-library/category/[slug].vue?macro=true";
import { default as _91id_936RWoW7MilHMeta } from "/app/src/pages/virtual-background-library/category/page/[id].vue?macro=true";
import { default as indexYrHFPSGGAUMeta } from "/app/src/pages/virtual-background-library/index.vue?macro=true";
import { default as _91id_930ZJkCt1j6zMeta } from "/app/src/pages/virtual-background-library/page/[id].vue?macro=true";
import { default as _91slug_93Q5X2kw50y5Meta } from "/app/src/pages/virtual-background-library/platform/[slug].vue?macro=true";
import { default as _91id_93wMBxnEOvFXMeta } from "/app/src/pages/virtual-background-library/platform/page/[id].vue?macro=true";
import { default as virtual_45event_45softwareVVR7kUBHYTMeta } from "/app/src/pages/virtual-event-software.vue?macro=true";
import { default as _91slug_93PeQCH4zuQOMeta } from "/app/src/pages/webinar-glossary/[slug].vue?macro=true";
import { default as indexLKYEk2K1ttMeta } from "/app/src/pages/webinar-glossary/index.vue?macro=true";
import { default as webinar_45promotiony3AUjzhV9hMeta } from "/app/src/pages/webinar-promotion.vue?macro=true";
import { default as _91slug_93OLarKjd7xFMeta } from "/app/src/pages/webinar-software-comparison/[slug].vue?macro=true";
import { default as index5g6Ju9qKE2Meta } from "/app/src/pages/webinar-software-comparison/index.vue?macro=true";
import { default as automated_45webinarveaBZit1yxMeta } from "/app/src/pages/webinar-software/automated-webinar.vue?macro=true";
import { default as indexmHUF4QAAtNMeta } from "/app/src/pages/webinar-software/index.vue?macro=true";
import { default as on_45demandMyhBnfkrSpMeta } from "/app/src/pages/webinar-software/on-demand.vue?macro=true";
import { default as webinar_45statisticsdKtxYwsf53Meta } from "/app/src/pages/webinar-statistics.vue?macro=true";
import { default as _91_46_46_46slug_93Z7QjJ4ZCF7Meta } from "/app/src/pages/webinar-tools/[...slug].vue?macro=true";
import { default as indexnAy2DnDYoaMeta } from "/app/src/pages/webinar-tools/index.vue?macro=true";
import { default as microphone_45testMpCkhXR2GSMeta } from "/app/src/pages/webinar-tools/microphone-test.vue?macro=true";
import { default as titles_45and_45outline_45generatorokxPCkcx3HMeta } from "/app/src/pages/webinar-tools/titles-and-outline-generator.vue?macro=true";
import { default as component_45stubJfVZno8SS3Meta } from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJfVZno8SS3 } from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug()",
    meta: _91slug_9375STYcaflOMeta || {},
    component: () => import("/app/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug()",
    meta: _91slug_9375STYcaflOMeta || {},
    component: () => import("/app/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    meta: _91slug_9375STYcaflOMeta || {},
    component: () => import("/app/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/sobre-nosotros",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/a-propos",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "agency-partners-slug___en",
    path: "/agency-partners/:slug()",
    meta: _91slug_93LA24mpzP4MMeta || {},
    component: () => import("/app/src/pages/agency-partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agency-partners-slug___fr",
    path: "/fr/agences-partenaires/:slug()",
    meta: _91slug_93LA24mpzP4MMeta || {},
    component: () => import("/app/src/pages/agency-partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agency-partners___en",
    path: "/agency-partners",
    meta: indexWmVTUjEEvlMeta || {},
    component: () => import("/app/src/pages/agency-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "agency-partners___fr",
    path: "/fr/agences-partenaires",
    meta: indexWmVTUjEEvlMeta || {},
    component: () => import("/app/src/pages/agency-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    meta: _91slug_93VDxieYqxciMeta || {},
    component: () => import("/app/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    meta: _91slug_93VDxieYqxciMeta || {},
    component: () => import("/app/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-author-slug___en",
    path: "/blog/author/:slug()",
    meta: _91slug_93c9FzndEQN1Meta || {},
    component: () => import("/app/src/pages/blog/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-author-slug___fr",
    path: "/fr/blog/auteur/:slug()",
    meta: _91slug_93c9FzndEQN1Meta || {},
    component: () => import("/app/src/pages/blog/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-author-page-id___en",
    path: "/blog/author/:slug()/page/:id()",
    meta: _91id_93RzgEV1uC4aMeta || {},
    component: () => import("/app/src/pages/blog/author/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-author-page-id___fr",
    path: "/fr/blog/auteur/:slug()/page/:id()",
    meta: _91id_93RzgEV1uC4aMeta || {},
    component: () => import("/app/src/pages/blog/author/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-slug___en",
    path: "/blog/category/:slug()",
    meta: _91slug_93U6TU4gewFHMeta || {},
    component: () => import("/app/src/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-slug___fr",
    path: "/fr/blog/categorie/:slug()",
    meta: _91slug_93U6TU4gewFHMeta || {},
    component: () => import("/app/src/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-page-id___en",
    path: "/blog/category/:slug()/page/:id()",
    meta: _91id_93xmV1jEDW26Meta || {},
    component: () => import("/app/src/pages/blog/category/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-page-id___fr",
    path: "/fr/blog/categorie/:slug()/page/:id()",
    meta: _91id_93xmV1jEDW26Meta || {},
    component: () => import("/app/src/pages/blog/category/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexUkEdP5hCQZMeta || {},
    component: () => import("/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexUkEdP5hCQZMeta || {},
    component: () => import("/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-page-id___en",
    path: "/blog/page/:id()",
    meta: _91id_931N4gy2EyUgMeta || {},
    component: () => import("/app/src/pages/blog/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-page-id___fr",
    path: "/fr/blog/page/:id()",
    meta: _91id_931N4gy2EyUgMeta || {},
    component: () => import("/app/src/pages/blog/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-search___en",
    path: "/blog/search",
    meta: searchgCucCyoHBjMeta || {},
    component: () => import("/app/src/pages/blog/search.vue").then(m => m.default || m)
  },
  {
    name: "blog-search___fr",
    path: "/fr/blog/recherche",
    meta: searchgCucCyoHBjMeta || {},
    component: () => import("/app/src/pages/blog/search.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    meta: contactmqHVTjI8koMeta || {},
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contact",
    meta: contactmqHVTjI8koMeta || {},
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    meta: contactmqHVTjI8koMeta || {},
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___en",
    path: "/customer-service",
    meta: customer_45servicet20ZrocbOuMeta || {},
    component: () => import("/app/src/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___es",
    path: "/es/servicio-al-cliente",
    meta: customer_45servicet20ZrocbOuMeta || {},
    component: () => import("/app/src/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___fr",
    path: "/fr/service-client",
    meta: customer_45servicet20ZrocbOuMeta || {},
    component: () => import("/app/src/pages/customer-service.vue").then(m => m.default || m)
  },
  {
    name: "customers-slug___en",
    path: "/customers/:slug()",
    meta: _91slug_93ayOQmhjm9JMeta || {},
    component: () => import("/app/src/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "customers-slug___es",
    path: "/es/clientes/:slug()",
    meta: _91slug_93ayOQmhjm9JMeta || {},
    component: () => import("/app/src/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "customers-slug___fr",
    path: "/fr/clients/:slug()",
    meta: _91slug_93ayOQmhjm9JMeta || {},
    component: () => import("/app/src/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "customers-filter-id___en",
    path: "/customers/filtered/:id()",
    meta: _91id_931kqe4pCZxrMeta || {},
    component: () => import("/app/src/pages/customers/filter/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-filter-id___es",
    path: "/es/clientes/filtrado/:id()",
    meta: _91id_931kqe4pCZxrMeta || {},
    component: () => import("/app/src/pages/customers/filter/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-filter-id___fr",
    path: "/fr/clients/filtre/:id()",
    meta: _91id_931kqe4pCZxrMeta || {},
    component: () => import("/app/src/pages/customers/filter/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers___en",
    path: "/customers",
    meta: index98zUuSNpEQMeta || {},
    component: () => import("/app/src/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers___es",
    path: "/es/clientes",
    meta: index98zUuSNpEQMeta || {},
    component: () => import("/app/src/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers___fr",
    path: "/fr/clients",
    meta: index98zUuSNpEQMeta || {},
    component: () => import("/app/src/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-pages-id___en",
    path: "/customers/page/:id()",
    meta: _91id_93szjmHBnyEpMeta || {},
    component: () => import("/app/src/pages/customers/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-pages-id___es",
    path: "/es/clientes/pagina/:id()",
    meta: _91id_93szjmHBnyEpMeta || {},
    component: () => import("/app/src/pages/customers/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-pages-id___fr",
    path: "/fr/clients/page/:id()",
    meta: _91id_93szjmHBnyEpMeta || {},
    component: () => import("/app/src/pages/customers/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "enterprise___en",
    path: "/enterprise",
    meta: enterpriseiryIcjYTovMeta || {},
    component: () => import("/app/src/pages/enterprise.vue").then(m => m.default || m)
  },
  {
    name: "enterprise___es",
    path: "/es/empresa",
    meta: enterpriseiryIcjYTovMeta || {},
    component: () => import("/app/src/pages/enterprise.vue").then(m => m.default || m)
  },
  {
    name: "enterprise___fr",
    path: "/fr/entreprise",
    meta: enterpriseiryIcjYTovMeta || {},
    component: () => import("/app/src/pages/enterprise.vue").then(m => m.default || m)
  },
  {
    name: "features___en",
    path: "/features",
    meta: featuresjNY3o2IWTUMeta || {},
    component: () => import("/app/src/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___es",
    path: "/es/caracteristicas",
    meta: featuresjNY3o2IWTUMeta || {},
    component: () => import("/app/src/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___fr",
    path: "/fr/fonctionnalites",
    meta: featuresjNY3o2IWTUMeta || {},
    component: () => import("/app/src/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "file-slug___en",
    path: "/file/:slug()",
    meta: _91slug_933eivOnKgCNMeta || {},
    component: () => import("/app/src/pages/file/[slug].vue").then(m => m.default || m)
  },
  {
    name: "file-slug___es",
    path: "/es/archivo/:slug()",
    meta: _91slug_933eivOnKgCNMeta || {},
    component: () => import("/app/src/pages/file/[slug].vue").then(m => m.default || m)
  },
  {
    name: "file-slug___fr",
    path: "/fr/fichier/:slug()",
    meta: _91slug_933eivOnKgCNMeta || {},
    component: () => import("/app/src/pages/file/[slug].vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker-games-slug___en",
    path: "/ice-breaker-games/:slug()",
    meta: _91slug_93HDAJZ3Zj6TMeta || {},
    component: () => import("/app/src/pages/ice-breaker-games/[slug].vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker-games___en",
    path: "/ice-breaker-games",
    meta: indexSZNRv0LzkpMeta || {},
    component: () => import("/app/src/pages/ice-breaker-games/index.vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker-games-page-id___en",
    path: "/ice-breaker-games/page/:id()",
    meta: _91id_93Ax9EWQpc7uMeta || {},
    component: () => import("/app/src/pages/ice-breaker-games/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker-questions-generator___en",
    path: "/ice-breaker-questions/generator",
    meta: generatorGpxx0FXR50Meta || {},
    component: () => import("/app/src/pages/ice-breaker-questions/generator.vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker-questions___en",
    path: "/ice-breaker-questions",
    meta: index89FfTu7xidMeta || {},
    component: () => import("/app/src/pages/ice-breaker-questions/index.vue").then(m => m.default || m)
  },
  {
    name: "ice-breaker___en",
    path: "/ice-breaker",
    meta: indexRccyW05sCWMeta || {},
    component: () => import("/app/src/pages/ice-breaker/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "industries-slug-tag___en",
    path: "/industries/:slug()/:tag()",
    meta: _91tag_93kgO3ruNvcHMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/[tag].vue").then(m => m.default || m)
  },
  {
    name: "industries-slug-tag___es",
    path: "/es/sectores/:slug()/:tag()",
    meta: _91tag_93kgO3ruNvcHMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/[tag].vue").then(m => m.default || m)
  },
  {
    name: "industries-slug-tag___fr",
    path: "/fr/secteurs/:slug()/:tag()",
    meta: _91tag_93kgO3ruNvcHMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/[tag].vue").then(m => m.default || m)
  },
  {
    name: "industries-slug___en",
    path: "/industries/:slug()",
    meta: indexkw4tVOaQ5DMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "industries-slug___es",
    path: "/es/sectores/:slug()",
    meta: indexkw4tVOaQ5DMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "industries-slug___fr",
    path: "/fr/secteurs/:slug()",
    meta: indexkw4tVOaQ5DMeta || {},
    component: () => import("/app/src/pages/industries/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "industries___en",
    path: "/industries",
    meta: index1vsYImkN24Meta || {},
    component: () => import("/app/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: "industries___es",
    path: "/es/sectores",
    meta: index1vsYImkN24Meta || {},
    component: () => import("/app/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: "industries___fr",
    path: "/fr/secteurs",
    meta: index1vsYImkN24Meta || {},
    component: () => import("/app/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations-slug___en",
    path: "/integrations/:slug()",
    meta: _91slug_93qEj37DRaCCMeta || {},
    component: () => import("/app/src/pages/integrations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "integrations-slug___es",
    path: "/es/integraciones/:slug()",
    meta: _91slug_93qEj37DRaCCMeta || {},
    component: () => import("/app/src/pages/integrations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "integrations-slug___fr",
    path: "/fr/integrations/:slug()",
    meta: _91slug_93qEj37DRaCCMeta || {},
    component: () => import("/app/src/pages/integrations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "integrations___en",
    path: "/integrations",
    meta: indexa6kqw29fbJMeta || {},
    component: () => import("/app/src/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations___es",
    path: "/es/integraciones",
    meta: indexa6kqw29fbJMeta || {},
    component: () => import("/app/src/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations___fr",
    path: "/fr/integrations",
    meta: indexa6kqw29fbJMeta || {},
    component: () => import("/app/src/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-center___en",
    path: "/legal-center",
    meta: legal_45centerYxOISCeytfMeta || {},
    component: () => import("/app/src/pages/legal-center.vue").then(m => m.default || m)
  },
  {
    name: "legal-center___fr",
    path: "/fr/legal-center",
    meta: legal_45centerYxOISCeytfMeta || {},
    component: () => import("/app/src/pages/legal-center.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___en",
    path: "/onboarding",
    meta: onboardinglQRWnZpm6fMeta || {},
    component: () => import("/app/src/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___es",
    path: "/es/onboarding",
    meta: onboardinglQRWnZpm6fMeta || {},
    component: () => import("/app/src/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___fr",
    path: "/fr/onboarding",
    meta: onboardinglQRWnZpm6fMeta || {},
    component: () => import("/app/src/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "online-meeting-software___en",
    path: "/online-meeting-software",
    meta: online_45meeting_45softwareXjIw4KHqVIMeta || {},
    component: () => import("/app/src/pages/online-meeting-software.vue").then(m => m.default || m)
  },
  {
    name: "online-meeting-software___es",
    path: "/es/herramienta-reuniones-video",
    meta: online_45meeting_45softwareXjIw4KHqVIMeta || {},
    component: () => import("/app/src/pages/online-meeting-software.vue").then(m => m.default || m)
  },
  {
    name: "online-meeting-software___fr",
    path: "/fr/logiciel-reunions-video",
    meta: online_45meeting_45softwareXjIw4KHqVIMeta || {},
    component: () => import("/app/src/pages/online-meeting-software.vue").then(m => m.default || m)
  },
  {
    name: "partners-slug___en",
    path: "/partners/:slug()",
    meta: _91slug_93MbhnreXBaTMeta || {},
    component: () => import("/app/src/pages/partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: "partners-slug___es",
    path: "/es/partners/:slug()",
    meta: _91slug_93MbhnreXBaTMeta || {},
    component: () => import("/app/src/pages/partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: "partners-slug___fr",
    path: "/fr/partenaires/:slug()",
    meta: _91slug_93MbhnreXBaTMeta || {},
    component: () => import("/app/src/pages/partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: "partners___en",
    path: "/partners",
    meta: indexwRzRVWjFWlMeta || {},
    component: () => import("/app/src/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___es",
    path: "/es/partners",
    meta: indexwRzRVWjFWlMeta || {},
    component: () => import("/app/src/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners___fr",
    path: "/fr/partenaires",
    meta: indexwRzRVWjFWlMeta || {},
    component: () => import("/app/src/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "powered-by-livestorm___en",
    path: "/powered-by-livestorm",
    meta: powered_45by_45livestormncoAycMHmqMeta || {},
    component: () => import("/app/src/pages/powered-by-livestorm.vue").then(m => m.default || m)
  },
  {
    name: "powered-by-livestorm___es",
    path: "/es/powered-by-livestorm",
    meta: powered_45by_45livestormncoAycMHmqMeta || {},
    component: () => import("/app/src/pages/powered-by-livestorm.vue").then(m => m.default || m)
  },
  {
    name: "powered-by-livestorm___fr",
    path: "/fr/powered-by-livestorm",
    meta: powered_45by_45livestormncoAycMHmqMeta || {},
    component: () => import("/app/src/pages/powered-by-livestorm.vue").then(m => m.default || m)
  },
  {
    name: "pricing-plans___en",
    path: "/pricing-plans",
    meta: pricing_45plansm8lEnXwwYfMeta || {},
    component: () => import("/app/src/pages/pricing-plans.vue").then(m => m.default || m)
  },
  {
    name: "pricing-plans___es",
    path: "/es/planes-de-precios",
    meta: pricing_45plansm8lEnXwwYfMeta || {},
    component: () => import("/app/src/pages/pricing-plans.vue").then(m => m.default || m)
  },
  {
    name: "pricing-plans___fr",
    path: "/fr/plans-tarifaires",
    meta: pricing_45plansm8lEnXwwYfMeta || {},
    component: () => import("/app/src/pages/pricing-plans.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en",
    path: "/pricing",
    meta: pricingUb7f7hCgwvMeta || {},
    component: () => import("/app/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___es",
    path: "/es/precios",
    meta: pricingUb7f7hCgwvMeta || {},
    component: () => import("/app/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___fr",
    path: "/fr/tarifs",
    meta: pricingUb7f7hCgwvMeta || {},
    component: () => import("/app/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "resources-type-slug___en",
    path: "/resources/:type()/:slug()",
    meta: _91slug_934UBvbrZ8UqMeta || {},
    component: () => import("/app/src/pages/resources/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-type-slug___fr",
    path: "/fr/ressources/:type()/:slug()",
    meta: _91slug_934UBvbrZ8UqMeta || {},
    component: () => import("/app/src/pages/resources/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-type___en",
    path: "/resources/:type()",
    meta: indexiTqH2duCGAMeta || {},
    component: () => import("/app/src/pages/resources/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-type___fr",
    path: "/fr/ressources/:type()",
    meta: indexiTqH2duCGAMeta || {},
    component: () => import("/app/src/pages/resources/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___en",
    path: "/resources/guides/:slug()",
    meta: _91slug_93aOzpkYR3OtMeta || {},
    component: () => import("/app/src/pages/resources/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___fr",
    path: "/fr/ressources/guides/:slug()",
    meta: _91slug_93aOzpkYR3OtMeta || {},
    component: () => import("/app/src/pages/resources/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources___en",
    path: "/resources",
    meta: index6HJHYQwJzeMeta || {},
    component: () => import("/app/src/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___fr",
    path: "/fr/ressources",
    meta: index6HJHYQwJzeMeta || {},
    component: () => import("/app/src/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-page-id___en",
    path: "/resources/page/:id()",
    meta: _91id_93tpV2iXlWGmMeta || {},
    component: () => import("/app/src/pages/resources/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "resources-page-id___fr",
    path: "/fr/ressources/page/:id()",
    meta: _91id_93tpV2iXlWGmMeta || {},
    component: () => import("/app/src/pages/resources/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "resources-quiz-slug___en",
    path: "/resources/quiz/:slug()",
    meta: _91slug_93OtBcB3l8bAMeta || {},
    component: () => import("/app/src/pages/resources/quiz/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restreaming___en",
    path: "/restreaming",
    meta: restreamingjUQDN0z1IZMeta || {},
    component: () => import("/app/src/pages/restreaming.vue").then(m => m.default || m)
  },
  {
    name: "restreaming___es",
    path: "/es/restreaming",
    meta: restreamingjUQDN0z1IZMeta || {},
    component: () => import("/app/src/pages/restreaming.vue").then(m => m.default || m)
  },
  {
    name: "restreaming___fr",
    path: "/fr/restreaming",
    meta: restreamingjUQDN0z1IZMeta || {},
    component: () => import("/app/src/pages/restreaming.vue").then(m => m.default || m)
  },
  {
    name: "social-links___en",
    path: "/social-links",
    meta: social_45linksXLBXe77FYwMeta || {},
    component: () => import("/app/src/pages/social-links.vue").then(m => m.default || m)
  },
  {
    name: "teams-slug___en",
    path: "/teams/:slug()",
    meta: _91slug_93qtiZ0s673jMeta || {},
    component: () => import("/app/src/pages/teams/[slug].vue").then(m => m.default || m)
  },
  {
    name: "teams-slug___es",
    path: "/es/equipos/:slug()",
    meta: _91slug_93qtiZ0s673jMeta || {},
    component: () => import("/app/src/pages/teams/[slug].vue").then(m => m.default || m)
  },
  {
    name: "teams-slug___fr",
    path: "/fr/equipes/:slug()",
    meta: _91slug_93qtiZ0s673jMeta || {},
    component: () => import("/app/src/pages/teams/[slug].vue").then(m => m.default || m)
  },
  {
    name: "teams___en",
    path: "/teams",
    meta: indexTzaOJAwYaDMeta || {},
    component: () => import("/app/src/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "teams___es",
    path: "/es/equipos",
    meta: indexTzaOJAwYaDMeta || {},
    component: () => import("/app/src/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "teams___fr",
    path: "/fr/equipes",
    meta: indexTzaOJAwYaDMeta || {},
    component: () => import("/app/src/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "try-slug___en",
    path: "/try/:slug()",
    meta: _91slug_937nqoKmY8OIMeta || {},
    component: () => import("/app/src/pages/try/[slug].vue").then(m => m.default || m)
  },
  {
    name: "try-slug___es",
    path: "/es/try/:slug()",
    meta: _91slug_937nqoKmY8OIMeta || {},
    component: () => import("/app/src/pages/try/[slug].vue").then(m => m.default || m)
  },
  {
    name: "try-slug___fr",
    path: "/fr/try/:slug()",
    meta: _91slug_937nqoKmY8OIMeta || {},
    component: () => import("/app/src/pages/try/[slug].vue").then(m => m.default || m)
  },
  {
    name: "use-cases-slug___en",
    path: "/use-cases/:slug()",
    meta: _91slug_9316VKC81habMeta || {},
    component: () => import("/app/src/pages/use-cases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "use-cases-slug___es",
    path: "/es/usos/:slug()",
    meta: _91slug_9316VKC81habMeta || {},
    component: () => import("/app/src/pages/use-cases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "use-cases-slug___fr",
    path: "/fr/cas-usage/:slug()",
    meta: _91slug_9316VKC81habMeta || {},
    component: () => import("/app/src/pages/use-cases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "use-cases___en",
    path: "/use-cases",
    meta: indexPZ6H1ChzmkMeta || {},
    component: () => import("/app/src/pages/use-cases/index.vue").then(m => m.default || m)
  },
  {
    name: "use-cases___es",
    path: "/es/usos",
    meta: indexPZ6H1ChzmkMeta || {},
    component: () => import("/app/src/pages/use-cases/index.vue").then(m => m.default || m)
  },
  {
    name: "use-cases___fr",
    path: "/fr/cas-usage",
    meta: indexPZ6H1ChzmkMeta || {},
    component: () => import("/app/src/pages/use-cases/index.vue").then(m => m.default || m)
  },
  {
    name: "video-glossary-slug___en",
    path: "/video-glossary/:slug()",
    meta: _91slug_93XnIc7UkZ5GMeta || {},
    component: () => import("/app/src/pages/video-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "video-glossary-slug___es",
    path: "/es/video-glossary/:slug()",
    meta: _91slug_93XnIc7UkZ5GMeta || {},
    component: () => import("/app/src/pages/video-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "video-glossary-slug___fr",
    path: "/fr/glossaire-video/:slug()",
    meta: _91slug_93XnIc7UkZ5GMeta || {},
    component: () => import("/app/src/pages/video-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "video-glossary___en",
    path: "/video-glossary",
    meta: indexMQu7d3rbdrMeta || {},
    component: () => import("/app/src/pages/video-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: "video-glossary___fr",
    path: "/fr/glossaire-video",
    meta: indexMQu7d3rbdrMeta || {},
    component: () => import("/app/src/pages/video-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-slug___en",
    path: "/virtual-background-library/:slug()",
    meta: _91slug_93IFhx9CVczbMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-slug___fr",
    path: "/fr/arriere-plan-virtuel/:slug()",
    meta: _91slug_93IFhx9CVczbMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-category-slug___en",
    path: "/virtual-background-library/category/:slug()",
    meta: _91slug_93ue005eES5pMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-category-slug___fr",
    path: "/fr/arriere-plan-virtuel/categorie/:slug()",
    meta: _91slug_93ue005eES5pMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-category-page-id___en",
    path: "/virtual-background-library/category/:slug()/page/:id()",
    meta: _91id_936RWoW7MilHMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/category/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-category-page-id___fr",
    path: "/fr/arriere-plan-virtuel/categorie/:slug()/page/:id()",
    meta: _91id_936RWoW7MilHMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/category/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library___en",
    path: "/virtual-background-library",
    meta: indexYrHFPSGGAUMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/index.vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library___fr",
    path: "/fr/arriere-plan-virtuel",
    meta: indexYrHFPSGGAUMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/index.vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-page-id___en",
    path: "/virtual-background-library/page/:id()",
    meta: _91id_930ZJkCt1j6zMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-page-id___fr",
    path: "/fr/arriere-plan-virtuel/page/:id()",
    meta: _91id_930ZJkCt1j6zMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-platform-slug___en",
    path: "/virtual-background-library/platform/:slug()",
    meta: _91slug_93Q5X2kw50y5Meta || {},
    component: () => import("/app/src/pages/virtual-background-library/platform/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-platform-slug___fr",
    path: "/fr/arriere-plan-virtuel/plateforme/:slug()",
    meta: _91slug_93Q5X2kw50y5Meta || {},
    component: () => import("/app/src/pages/virtual-background-library/platform/[slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-platform-page-id___en",
    path: "/virtual-background-library/platform/:slug()/page/:id()",
    meta: _91id_93wMBxnEOvFXMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/platform/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-background-library-platform-page-id___fr",
    path: "/fr/arriere-plan-virtuel/plateforme/:slug()/page/:id()",
    meta: _91id_93wMBxnEOvFXMeta || {},
    component: () => import("/app/src/pages/virtual-background-library/platform/page/[id].vue").then(m => m.default || m)
  },
  {
    name: "virtual-event-software___en",
    path: "/virtual-event-software",
    meta: virtual_45event_45softwareVVR7kUBHYTMeta || {},
    component: () => import("/app/src/pages/virtual-event-software.vue").then(m => m.default || m)
  },
  {
    name: "virtual-event-software___es",
    path: "/es/herramienta-eventos-virtuales",
    meta: virtual_45event_45softwareVVR7kUBHYTMeta || {},
    component: () => import("/app/src/pages/virtual-event-software.vue").then(m => m.default || m)
  },
  {
    name: "virtual-event-software___fr",
    path: "/fr/logiciel-evenements-virtuels",
    meta: virtual_45event_45softwareVVR7kUBHYTMeta || {},
    component: () => import("/app/src/pages/virtual-event-software.vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary-slug___en",
    path: "/webinar-glossary/:slug()",
    meta: _91slug_93PeQCH4zuQOMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary-slug___es",
    path: "/es/glosario-webinar/:slug()",
    meta: _91slug_93PeQCH4zuQOMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary-slug___fr",
    path: "/fr/glossaire-webinar/:slug()",
    meta: _91slug_93PeQCH4zuQOMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary___en",
    path: "/webinar-glossary",
    meta: indexLKYEk2K1ttMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary___es",
    path: "/es/glosario-webinar",
    meta: indexLKYEk2K1ttMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-glossary___fr",
    path: "/fr/glossaire-webinar",
    meta: indexLKYEk2K1ttMeta || {},
    component: () => import("/app/src/pages/webinar-glossary/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-promotion___en",
    path: "/webinar-promotion",
    meta: webinar_45promotiony3AUjzhV9hMeta || {},
    component: () => import("/app/src/pages/webinar-promotion.vue").then(m => m.default || m)
  },
  {
    name: "webinar-promotion___es",
    path: "/es/webinar-promotion",
    meta: webinar_45promotiony3AUjzhV9hMeta || {},
    component: () => import("/app/src/pages/webinar-promotion.vue").then(m => m.default || m)
  },
  {
    name: "webinar-promotion___fr",
    path: "/fr/webinar-promotion",
    meta: webinar_45promotiony3AUjzhV9hMeta || {},
    component: () => import("/app/src/pages/webinar-promotion.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison-slug___en",
    path: "/webinar-software-comparison/:slug()",
    meta: _91slug_93OLarKjd7xFMeta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison-slug___es",
    path: "/es/comparacion-software-seminarios-web/:slug()",
    meta: _91slug_93OLarKjd7xFMeta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison-slug___fr",
    path: "/fr/comparatif-logiciels-webinar/:slug()",
    meta: _91slug_93OLarKjd7xFMeta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/[slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison___en",
    path: "/webinar-software-comparison",
    meta: index5g6Ju9qKE2Meta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison___es",
    path: "/es/comparacion-software-seminarios-web",
    meta: index5g6Ju9qKE2Meta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-comparison___fr",
    path: "/fr/comparatif-logiciels-webinar",
    meta: index5g6Ju9qKE2Meta || {},
    component: () => import("/app/src/pages/webinar-software-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-automated-webinar___en",
    path: "/webinar-software/automated-webinars",
    meta: automated_45webinarveaBZit1yxMeta || {},
    component: () => import("/app/src/pages/webinar-software/automated-webinar.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-automated-webinar___es",
    path: "/es/herramienta-webinar/webinars-automatizados",
    meta: automated_45webinarveaBZit1yxMeta || {},
    component: () => import("/app/src/pages/webinar-software/automated-webinar.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-automated-webinar___fr",
    path: "/fr/logiciel-webinars/webinars-automatiques",
    meta: automated_45webinarveaBZit1yxMeta || {},
    component: () => import("/app/src/pages/webinar-software/automated-webinar.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software___en",
    path: "/webinar-software",
    meta: indexmHUF4QAAtNMeta || {},
    component: () => import("/app/src/pages/webinar-software/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software___es",
    path: "/es/herramienta-webinar",
    meta: indexmHUF4QAAtNMeta || {},
    component: () => import("/app/src/pages/webinar-software/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software___fr",
    path: "/fr/logiciel-webinars",
    meta: indexmHUF4QAAtNMeta || {},
    component: () => import("/app/src/pages/webinar-software/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-on-demand___en",
    path: "/webinar-software/on-demand-webinars",
    meta: on_45demandMyhBnfkrSpMeta || {},
    component: () => import("/app/src/pages/webinar-software/on-demand.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-on-demand___es",
    path: "/es/herramienta-webinar/webinars-bajo-demanda",
    meta: on_45demandMyhBnfkrSpMeta || {},
    component: () => import("/app/src/pages/webinar-software/on-demand.vue").then(m => m.default || m)
  },
  {
    name: "webinar-software-on-demand___fr",
    path: "/fr/logiciel-webinars/webinars-sur-demande",
    meta: on_45demandMyhBnfkrSpMeta || {},
    component: () => import("/app/src/pages/webinar-software/on-demand.vue").then(m => m.default || m)
  },
  {
    name: "webinar-statistics___en",
    path: "/webinar-statistics",
    meta: webinar_45statisticsdKtxYwsf53Meta || {},
    component: () => import("/app/src/pages/webinar-statistics.vue").then(m => m.default || m)
  },
  {
    name: "webinar-statistics___es",
    path: "/es/estadisticas-seminarios-web",
    meta: webinar_45statisticsdKtxYwsf53Meta || {},
    component: () => import("/app/src/pages/webinar-statistics.vue").then(m => m.default || m)
  },
  {
    name: "webinar-statistics___fr",
    path: "/fr/webinar-statistiques",
    meta: webinar_45statisticsdKtxYwsf53Meta || {},
    component: () => import("/app/src/pages/webinar-statistics.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-slug___en",
    path: "/tools/:slug(.*)*",
    meta: _91_46_46_46slug_93Z7QjJ4ZCF7Meta || {},
    component: () => import("/app/src/pages/webinar-tools/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-slug___es",
    path: "/es/herramientas/:slug(.*)*",
    meta: _91_46_46_46slug_93Z7QjJ4ZCF7Meta || {},
    component: () => import("/app/src/pages/webinar-tools/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-slug___fr",
    path: "/fr/outils/:slug(.*)*",
    meta: _91_46_46_46slug_93Z7QjJ4ZCF7Meta || {},
    component: () => import("/app/src/pages/webinar-tools/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools___en",
    path: "/tools",
    meta: indexnAy2DnDYoaMeta || {},
    component: () => import("/app/src/pages/webinar-tools/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools___es",
    path: "/es/herramientas",
    meta: indexnAy2DnDYoaMeta || {},
    component: () => import("/app/src/pages/webinar-tools/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools___fr",
    path: "/fr/outils",
    meta: indexnAy2DnDYoaMeta || {},
    component: () => import("/app/src/pages/webinar-tools/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-microphone-test___en",
    path: "/tools/microphone-test",
    meta: microphone_45testMpCkhXR2GSMeta || {},
    component: () => import("/app/src/pages/webinar-tools/microphone-test.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-microphone-test___es",
    path: "/es/herramientas/probar-microfono",
    meta: microphone_45testMpCkhXR2GSMeta || {},
    component: () => import("/app/src/pages/webinar-tools/microphone-test.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-microphone-test___fr",
    path: "/fr/outils/tester-microphone",
    meta: microphone_45testMpCkhXR2GSMeta || {},
    component: () => import("/app/src/pages/webinar-tools/microphone-test.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-titles-and-outline-generator___en",
    path: "/tools/webinar-title-outline-generator",
    meta: titles_45and_45outline_45generatorokxPCkcx3HMeta || {},
    component: () => import("/app/src/pages/webinar-tools/titles-and-outline-generator.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-titles-and-outline-generator___es",
    path: "/es/herramientas/generador-webinar-titulo-plan",
    meta: titles_45and_45outline_45generatorokxPCkcx3HMeta || {},
    component: () => import("/app/src/pages/webinar-tools/titles-and-outline-generator.vue").then(m => m.default || m)
  },
  {
    name: "webinar-tools-titles-and-outline-generator___fr",
    path: "/fr/outils/generateur-webinar-titre-plan",
    meta: titles_45and_45outline_45generatorokxPCkcx3HMeta || {},
    component: () => import("/app/src/pages/webinar-tools/titles-and-outline-generator.vue").then(m => m.default || m)
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/*/sales@livestorm.co",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/allurion/webinar-elipse-chez-pondera-1",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/automated",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/automated-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/automated-webinars/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/automated/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/best-virtual-meeting-platforms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/6-keys-perfect-webinar-keynote-powerpoint",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/6-marketing-problems-solve-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/6-tips-for-running-paid-webinars/%7D",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/announcing-livestorm-meet",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/best-tools-to-add-to-web-conference",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/better-webinar-retargeting-with-customer-io",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/customer-engagement",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/gocheck-kids-webinar-automation",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-accelerate-sales-cycles-with-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-build-a-customer-education-webinar-program",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-create-a-webinar-preparation-framework",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-create-a-webinar-the-perfect-setup",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-create-and-host-a-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-create-on-demand-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-give-great-product-demos-that-scale",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-host-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-include-webinars-in-your-customer-lifecycle",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/how-to-run-effective-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/internal-communication-for-medium-sized-teams",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/learn-how-to-deliver-personalized-communication-at-scale",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/live-webinars-customer-lifecycle",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/marketing-webinar-automation-one-click-registrations-from-email-campaigns",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/marketing-webinar-automation-zapier-livestorm-crm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/on-demand-webinars-customer-lifecycle",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/optimizing-nps-customer-surveys-automation",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/real-time-marketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/remote-work-tips-for-beginners",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/sales-roi-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/scaling-your-customer-success-team/asana.com",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/state-of-web-conferencing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/video-marketing-web-conferencing-trends-2018",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-integration-zapier",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-marketing-guide-2-getting-started",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-marketing-guide-part-1",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-microphone-scarlett-2i2-review",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-promotion-best-practices",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-promotion-checklist-2016-edition",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webinar-promotion-strategies-2017",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/what-is-a-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/clientes/wppWPP",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/compatibility",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/demo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/demo/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/ayudenos/help-us",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/adobeconnect-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/adobeconnect-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/adobeconnect-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/adobeconnect-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/adobeconnect-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/crowdcast-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/crowdcast-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/crowdcast-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-microsoftteams",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/googlemeet-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/gotowebinar-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/microsoftteams-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/on24-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/on24-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/on24-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/on24-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webex-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webikeo-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webinarjam-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/webinarjam-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/comparacion-software-seminarios-web/zoom-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/formacion-seminarios-web",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/gdpr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/gdpr/gdpr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/gdpr/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/geschaftsbedingungen",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/geschaftsbedingungen/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/glosario-webinar/promocion-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/glosario-webinar/software-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/glosario-webinar/visioconferencia",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/herramienta-reuniones-video/reuniones-programadas",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/integraciones/barra-lateral-externa",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/integraciones/contador",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/integraciones/infusionsoft",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/integraciones/integromat",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/integraciones/nocrmio",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/landing-page/form",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/landing-page/form/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/legal-center",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/legal-notice",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/legal-notice/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/meet",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/meet/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/null",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/on-demand",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/on-demand/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/partners-terms/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/partners-terms/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/plugins-sdk",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/privacy-policy",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/privacy-policy/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/privacy-policy/privacy-policy",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/saascast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/saastr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/seminarios-web-integraciones",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/seminarios-web-integraciones/:pathMatch(.*)",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/software-de-videoconferencias/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/software-gratis-seminarios-web/free-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/termini-e-condizioni",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/termini-e-condizioni/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/terms/terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-adobeconnect-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-gotowebinar-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-on24-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-webex-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-webikeo-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-webinar-software/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/best-webinarjam-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/demo/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/hubspot-integration/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/online-meetings/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/remote-work/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/thank-you-demo-webinar/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/webinar-features/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/webinar-for-education/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/webinar-room/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/try/webinars-for-saas/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/usos/resources",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/webinars-automatizados",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/webinars-automatizados/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/webinars-bajo-demanda",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/es/webinars-bajo-demanda/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/f",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/faq",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr,",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/404",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/cas-usage/logiciel-webcast-podcast-interview",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/cas-usage/logiciel-webcast-podcast-interview/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/clients/fr/webinar-glossary",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/adobeconnect-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/adobeconnect-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/adobeconnect-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/adobeconnect-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/adobeconnect-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/crowdcast-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/crowdcast-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/crowdcast-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-microsoftteams",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/googlemeet-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/gotowebinar-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/microsoftteams-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/on24-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/on24-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/on24-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/on24-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webex-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webikeo-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webinarjam-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/webinarjam-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/comparatif-logiciels-webinar/zoom-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/conditions-generales",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/conditions-generales/terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/devenez-un-partenaire-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/evenements",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/formation-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/geschaftsbedingungen",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/geschaftsbedingungen/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/barre-laterale-externe",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/custoemr-io",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/fr/webinar-glossary",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/google-agenda",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/infusionsoft",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/integromat",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/integrations/minuteur",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/logiciel-reunions-video/reunions-planifiees",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/logiciel-visioconference",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/logiciel-webcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/meet",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/meet/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/mentions-legales/legal-notice",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/on-demand",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/on-demand/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/outil-logiciel-webinar-gratuit/free-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/partners-terms/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/partners-terms/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/plugins-sdk",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/politique-confidentialite",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/politique-confidentialite/privacy-policy",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/rgpd.",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/rgpd/gdpr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/saascast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/saastr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/termini-e-condizioni",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/termini-e-condizioni/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/best-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/best-zoom-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/demo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleur-logiciel-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/meilleure-alternative-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/reunions-en-ligne",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/secret",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/teletravail",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/thank-you-demo-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/webinaires-fonctionnalites",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/webinaires-pour-saas",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/webinar-features",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_forms/webinar-for-education",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/translation%20missing:%20fr.landing_page_outbounds/webinar-room",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/try/best-webinar-software/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/try/best-zoom-alternative/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/try/webinaires-pour-saas",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/video-meeting-software/",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinar-integrations",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinar-integrations/:pathMatch(.*)",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars-automatiques",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars-automatiques/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars-sur-demande",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars-sur-demande/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars/webinars-generation-leads",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars/webinars-inbound",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/fr/webinars/webinars-onboarding",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/free-webinar-software/free-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/gdpr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/gdpr/gdpr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/help-us/help-us",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/ice-breaker-games/page/1",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/integrations/infusionsoft",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/integrations/integromat",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/integrations/nocrmio",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/jp/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/klausapp/how-to-supercharge-your-support-team-with-intelligent-tools",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/legal-notice/legal-notice",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/live",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/meet",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/meet/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/more-interactive-virtual-meetings",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/nortonsw_c0f58540-a7d2-0",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/nortonsw_c0f58540-a7d2-0.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/nortonsw_f8b50300-a0d2-0",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/on-demand",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/on-demand-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/on-demand-webinars/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/on-demand/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/online-meeting-software/scheduled-meetings",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/partners-terms/partners-terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/partners/partenaires-comarketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/partners/partners-afiliados",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/partners/partners-marketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/plugins-sdk",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/privacy-policy/%20target=",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/engaging-audience-social-media",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guide-online-meetings",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/customer-onboarding",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/employee-onboarding-process",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/event-marketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/internal-communication-strategy",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/partnership-marketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/remote-work",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/social-media-event-promotion",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/video-engagement",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/virtual-conference",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/virtual-meetings",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/virtual-selling",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/webinar-best-practices",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/guides/what-is-virtual-learning",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/interactive-virtual-events",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/online-event-promotion",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/sales-relationship-funnel",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/state-video-engagement-report",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/resources/webinar-crash-course",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/saascast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/saastr",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/terms-and-conditions",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/terms/*",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/terms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-adobeconnect-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-crowdcast-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-on24-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-webikeo-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-webinarjam-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/best-zoom-alternative",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/demo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/remote-work",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/secret",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/thank-you-demo-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/webinar-features",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/webinar-for-education",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_forms/webinars-for-saas",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/translation%20missing:%20en.landing_page_outbounds/webinar-room",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/try/interactive-virtual-events",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/try/resources/complete-guide-online-event-promotion",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/try/resources/interactive-virtual-events",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/try/webinars-for-saas",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/use-cases/ama",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/use-cases/webinar-software-for-webcasts-and-interviews",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/use-cases/webinar-software-for-webcasts-and-interviews/index.html",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/video-conferencing-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/video-meeting-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webcasting-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-crash-course",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/conferencia-online",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/difusion-streaming",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/ecran-infini",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/gated-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/inscrito",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/integracion-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/latence",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/latencia",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/live-webinar/https//livestorm.co/webinar-glossary/webinar-dashboard",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/live-webinar/https//livestorm.co/webinar-glossary/webinar-registrants",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/metricas-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/microfono-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/microphone-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/pantalla-infinita",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/pare-feu",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/preguntas-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/presentador-invitado",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/programmer-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/promocion-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/recycler-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/repurposing-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/scheduling-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/web-conferencing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-%20guest-speaker",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-automatise",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-automatisé",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-en-directo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-guest-speaker",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-host",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-landing-page",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-microphone",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-mistakes",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-participantes",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-participants",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-promotion",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-recurrente",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinar-webcam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/webinars-a-la-carta",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-integrations",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-integrations/:pathMatch(.*)",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-integrations/webhooks%3EDocumentation%20link%3C/a%3E%3Cbr%3EManychat%20-%20%3Ca%20href=",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/adobeconnect-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/adobeconnect-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/adobeconnect-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/adobeconnect-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/adobeconnect-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/crowdcast-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/crowdcast-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/crowdcast-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-microsoftteams",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/googlemeet-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/gotowebinar-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-webikeo",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/microsoftteams-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/on24-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/on24-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/on24-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/on24-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webex-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-webex",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webikeo-vs-zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webinarjam-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/webinarjam-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-adobeconnect",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-crowdcast",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-gotowebinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-livestorm",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-on24",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-software-comparison/zoom-vs-webinarjam",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-statistics/%3Ca%20href=",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/best-webinar-platforms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/guide-to-choosing-webinar-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/online-video-meeting-checklist",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/choose-best-video-conferencing-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/smart-webinar-tips-from-14-marketing-experts",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/video-engagement-marketing-trend",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/video-engagement-metrics",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/webinar-glossary/pre-recorded-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/guide-social-media-event-marketing",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/essential-social-media-metrics-event",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/social-media-promotion-events",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/14-ideas-for-promoting-your-next-online-event",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/virtual-summit-checklist",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/virtual-summit-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/webcam-microphone-for-webinars",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/everything-you-need-to-know-to-create-your-first-webinar",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/best-meeting-management-software",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/best-virtual-meeting-platforms",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/online-meeting-tools",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/virtual-interview-tips",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/partnership-marketing-event-strategy",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/event-strategy-marketing-partnerships",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/next-video-communication",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/online-video-communication-trends",
    component: component_45stubJfVZno8SS3
  },
  {
    name: component_45stubJfVZno8SS3Meta?.name,
    path: "/blog/virtual-event-marketing-guide",
    component: component_45stubJfVZno8SS3
  }
]